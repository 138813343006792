import { SchoolWithMetadata, UserProfile } from '@dao';
import { ButtonLink, EmptyState, getAddSchoolRoute, Loading } from '@shared';
import { SchoolCard } from './SchoolCard';

export interface ResultsProps {
  isLoading: boolean;
  profile?: UserProfile;
  schools: SchoolWithMetadata[];
}

export function Results({ isLoading, profile, schools }: ResultsProps) {
  if (isLoading && schools.length === 0) {
    return <Loading />;
  }

  if (schools.length < 1) {
    return (
      <EmptyState msg="We couldn't find anything matching that query.">
        <div>Know of a school that should be here?</div>

        <ButtonLink href={getAddSchoolRoute()}>Add a school</ButtonLink>
      </EmptyState>
    );
  }

  return (
    <>
      {schools.map((school) => (
        <SchoolCard
          key={school.school.id}
          profile={profile}
          school={school.school}
          schoolMetadata={school.metadata}
        />
      ))}
    </>
  );
}
