import { School } from '@dao';
import styled from 'styled-components';

interface NameWarningProps {
  schools: School[];
}

const Wrapper = styled.div`
  border: 2px solid yellow;
  padding: 15px;
  width: 100%;

  span {
    font-weight: 700;
  }
`;

const SchoolLink = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: blue;
  }
`;

export function NameWarning({ schools }: NameWarningProps) {
  if (!schools.length) {
    return null;
  }

  return (
    <Wrapper>
      <span>Please note:</span>
      {schools.length === 1
        ? ' 1 school already has this name.'
        : ` ${schools.length} schools already have this name.`}
      <ul>
        {schools.map((school) => (
          <li key={school.id}>
            <SchoolLink href={school.route} target="_blank">
              {school.name}
            </SchoolLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
}
