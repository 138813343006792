import { School, UserProfile } from '@dao';
import { FaEdit } from 'react-icons/fa';
import { Link } from './Link';

interface EditProps {
  profile?: UserProfile;
  school?: School;
}

export function Edit({ profile, school }: EditProps) {
  if (!profile || !school) {
    return null;
  }

  if (!school.editors.includes(profile.uid) && !profile.isAdmin) {
    return null;
  }

  return <Link href={school.editRoute} icon={<FaEdit />} text="Edit" />;
}
