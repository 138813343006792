import { School, SchoolDao, SchoolVisibility, UserProfile } from '@dao';
import { useTextInput } from '@hooks';
import {
  Accordion,
  AddressWithMap,
  Button,
  Card,
  Checkbox,
  ERROR_HAS_BEEN_REPORTED,
  ERROR_OCCURRED,
  Form,
  FormError,
  FormLabelRow,
  FormRow,
  FormRowNote,
  FormSectionTitle,
  Heading,
  logError,
  SearchInput,
  SecondHeading,
  Section,
  Select,
  TextAreaInput,
  TextInput,
} from '@shared';
import {
  formatPhone,
  formatVideoEmbed,
  getSocialHandle,
  scrollTo,
  toDollarString,
} from '@utils';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import { BooleanAttributesWrapper } from '../filters/BooleanAttributesWrapper';
import { useAddressSelector } from '../hooks/useAddressSelector';
import { useCityStateOptions } from '../hooks/useCityStateOptions';
import { useProgramInput } from '../hooks/useProgramInput';
import { SchoolSearchFilterOptions } from '../search/filters/types';
import {
  BOOLEAN_SCHOOL_ATTRIBUTE_LIST,
  getSchoolAttributeKey,
  getSchoolAttributeName,
} from '../utils';

interface EditSchoolFormProps {
  onSchoolUpdated: () => void;
  profile: UserProfile;
  school: School;
}

const Wrapper = styled(Card)`
  margin: 0 auto;
  max-width: 600px;
  padding: 1rem 2rem;
  width: 100%;
`;

const Title = styled(FormSectionTitle)`
  width: auto;
`;

export function EditSchoolForm({
  onSchoolUpdated,
  profile,
  school,
}: EditSchoolFormProps) {
  // State variables.
  const [attributes, setAttributes] = useState<SchoolSearchFilterOptions>(
    school.attributes,
  );
  const [visibility, setVisibility] = useState<SchoolVisibility>(
    school?.visibility ?? SchoolVisibility.Draft,
  );
  const [isLoading, setLoading] = useState(false);

  const address = useAddressSelector(
    {
      address: school.data.address,
      city: school.city,
      state: school.stateCode,
      zip: school.zip,
      latitude: school.latLon?.lat ?? null,
      longitude: school.latLon?.lng ?? null,
    },
    `${school.city}, ${school.stateCode}`,
    school.data.address,
  );
  const description = useTextInput(school.description);
  const email = useTextInput(school.email);
  const facebook = useTextInput(school.facebookUrl);
  const instagram = useTextInput(school.instagramUrl);
  const linkedIn = useTextInput(school.linkedInUrl);
  const name = useTextInput(school.name);
  const phone = useTextInput(school.phone);
  const twitter = useTextInput(school.twitterUrl);
  const videoEmbed = useTextInput(school.data.videoEmbedUrl);
  const website = useTextInput(school.websiteUrl);
  const youtube = useTextInput(school.youtubeUrl);

  const programs = useProgramInput(school.programs);

  const formError =
    name.error ||
    description.error ||
    address.addressErr ||
    description.error ||
    phone.error ||
    website.error ||
    videoEmbed.error ||
    email.error;

  // DOM references.
  const nameRef = useRef<HTMLDivElement>();
  const descriptionRef = useRef<HTMLDivElement>();
  const addressRef = useRef<HTMLDivElement>();

  const cityStateOptions = useCityStateOptions(address.cityState);

  function handleVisibilityChange(event: ChangeEvent<HTMLSelectElement>) {
    setVisibility(Number(event.target.value) as SchoolVisibility);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    // The following checks should be kept in sync with `isPublishable()` in
    // src/dao/entities/school.ts.

    if (!name.value.trim()) {
      scrollTo(nameRef);
      return name.updateError('Please enter a name for the school.');
    }

    if (!description.value.trim()) {
      scrollTo(descriptionRef);
      return description.updateError(
        'Please enter a description for the school.',
      );
    }

    if (!address.address?.address && !address.text.trim().length) {
      scrollTo(addressRef);
      return address.updateAddressError(
        'Type in the address and select one of the presented options.',
      );
    }

    if (!address.cityState) {
      return address.updateCityStateError('Please select a city and state.');
    }

    if (programs.validate()) {
      return;
    }

    const [city, state] = address.cityState.split(', ');
    const addressData =
      address.address?.address === address.text
        ? { ...address.address }
        : {
            address: address.text,
            city,
            latitude: null,
            longitude: null,
            state: state || '',
            zip: address.address?.zip || '',
          };

    // Save school details.
    const updatedSchool = School.create(
      {
        description: description.value,
        name: name.value,
        phone: phone.value,
        programs: programs.format(),
        website: website.value,
        social: {
          fb: getSocialHandle(facebook.value),
          ig: getSocialHandle(instagram.value),
          in: getSocialHandle(linkedIn.value),
          tw: getSocialHandle(twitter.value),
          yt: getSocialHandle(youtube.value),
        },
        videoEmbedUrl: videoEmbed.value,
        email: email.value,
        ...addressData,
        ...attributes,
      },
      visibility,
      school.editors,
      school.id,
    );

    setLoading(true);
    SchoolDao.updateSchool(updatedSchool)
      .then(onSchoolUpdated)
      .catch((error) => {
        logError(error);
        alert(`${ERROR_OCCURRED} ${ERROR_HAS_BEEN_REPORTED}`);
      });
  }

  return (
    <>
      <Section highlight>
        <Heading white>{name.value}</Heading>
      </Section>
      <Section>
        <Wrapper>
          <Form alignItems="center" onSubmit={handleSubmit}>
            <SecondHeading>Edit school details</SecondHeading>

            {/* School information */}
            <Accordion
              defaultExpanded={!name.value || !description.value}
              title={<Title>School information</Title>}>
              <FormRow>
                <FormLabelRow ref={nameRef}>School name</FormLabelRow>
                <TextInput
                  disabled={isLoading}
                  hasError={!!name.error}
                  isLoading={isLoading}
                  onChange={name.updateValue}
                  placeholder="School name"
                  type="text"
                  value={name.value}
                />
                <FormError msg={name.error} />
              </FormRow>

              <FormRow>
                <FormLabelRow ref={descriptionRef}>Description</FormLabelRow>
                <TextAreaInput
                  autoComplete="off"
                  hasError={!!description.error}
                  isLoading={isLoading}
                  onChange={description.updateValue}
                  placeholder="School description"
                  rows={3}
                  type="text"
                  value={description.value}
                />
                <FormError msg={description.error} />
              </FormRow>
            </Accordion>

            {/* Programs */}
            <Accordion defaultExpanded title={<Title>Programs</Title>}>
              <FormRow>
                <FormLabelRow>Attributes</FormLabelRow>
                <BooleanAttributesWrapper>
                  {BOOLEAN_SCHOOL_ATTRIBUTE_LIST.map((attribute) => {
                    const key = getSchoolAttributeKey(attribute);
                    const label = getSchoolAttributeName(attribute);
                    const active = !!attributes[key];

                    function handleChange(newValue: boolean) {
                      setAttributes((currAttributes) => {
                        return { ...currAttributes, [key]: newValue };
                      });
                    }

                    return (
                      <Checkbox
                        checked={active}
                        key={key}
                        label={label}
                        name={attribute}
                        onChange={handleChange}
                      />
                    );
                  })}
                </BooleanAttributesWrapper>
              </FormRow>

              <FormRow>
                <FormLabelRow>Programs</FormLabelRow>
                <Button
                  disabled={isLoading}
                  onClick={programs.addFirst}
                  type="button">
                  Add A Program
                </Button>

                {programs.value.map((program, index) => (
                  <FormRow key={index}>
                    <TextInput
                      onChange={programs.update(index, 'name')}
                      placeholder="Program Name"
                      type="text"
                      value={program.name}
                    />

                    <TextAreaInput
                      onChange={programs.update(index, 'description')}
                      placeholder="Program Description"
                      value={program.description}
                    />

                    <TextInput
                      formatter={toDollarString}
                      onChange={programs.update(index, 'tuition')}
                      placeholder="Program Tuition"
                      step="0.01"
                      type="number"
                      value={program.tuition}
                    />

                    <FormError msg={programs.errors[index]} />

                    <FormRow horizontal>
                      <Button
                        disabled={isLoading}
                        onClick={() => programs.append(index)}
                        type="button">
                        Add Program
                      </Button>

                      <Button
                        disabled={isLoading}
                        onClick={() => programs.remove(index)}
                        type="button"
                        warning>
                        Remove Program
                      </Button>
                    </FormRow>
                  </FormRow>
                ))}
              </FormRow>
            </Accordion>

            {/* Contact */}
            <Accordion
              defaultExpanded={!address.text || !phone.value || !website.value}
              title={<Title>Contact</Title>}>
              <FormRow>
                <FormLabelRow ref={addressRef}>Address</FormLabelRow>
                <AddressWithMap
                  address={address.address}
                  isLoading={isLoading}
                  hasError={!!address.addressErr}
                  onChange={address.updateText}
                  onSelect={address.selectAddress}
                  placeholder="Address"
                  type="text"
                  value={address.text}
                />
                <FormError msg={address.addressErr} />
              </FormRow>

              <FormRow horizontal>
                <div>
                  <SearchInput<string>
                    flush
                    onChange={address.updateCityState}
                    onSelect={address.selectCityState}
                    options={cityStateOptions}
                    placeholder="City, State"
                    value={address.cityState}
                  />
                  <FormError msg={address.cityStateErr} />
                </div>

                <TextInput
                  onChange={address.updateZip}
                  placeholder="ZIP"
                  type="text"
                  value={address.address?.zip || ''}
                />
              </FormRow>

              <FormRow horizontal justifyContent="space-between">
                <FormRow width="200px">
                  <FormLabelRow>Phone</FormLabelRow>
                  <TextInput
                    disabled={isLoading}
                    formatter={formatPhone}
                    hasError={!!phone.error}
                    onChange={phone.updateValue}
                    placeholder="Contact phone #"
                    type="text"
                    value={phone.value}
                  />
                  <FormError msg={phone.error} />
                </FormRow>

                <FormRow padLeft>
                  <FormLabelRow>Website</FormLabelRow>
                  <TextInput
                    disabled={isLoading}
                    hasError={!!website.error}
                    isLoading={isLoading}
                    onChange={website.updateValue}
                    placeholder="https://example.com"
                    type="text"
                    value={website.value}
                  />
                  <FormError msg={website.error} />
                </FormRow>
              </FormRow>

              <FormRow>
                <FormLabelRow>School Email</FormLabelRow>
                <TextInput
                  disabled={isLoading}
                  hasError={!!email.error}
                  isLoading={isLoading}
                  onChange={email.updateValue}
                  type="email"
                  value={email.value}
                />
                <FormError msg={email.error} />
              </FormRow>
            </Accordion>

            {/* Social */}
            <Accordion title={<Title>Social</Title>}>
              <FormRow>
                <FormLabelRow>Video Embed</FormLabelRow>
                <TextInput
                  disabled={isLoading}
                  formatter={formatVideoEmbed}
                  hasError={!!videoEmbed.error}
                  isLoading={isLoading}
                  onChange={videoEmbed.updateValue}
                  placeholder="Paste the code for a YouTube video embed here"
                  type="text"
                  value={videoEmbed.value}
                />
                <FormRowNote>This is a premium feature</FormRowNote>
                <FormError msg={videoEmbed.error} />
              </FormRow>

              <FormRow horizontal justifyContent="space-between">
                <FormRow half>
                  <FormLabelRow>Facebook</FormLabelRow>
                  <TextInput
                    disabled={isLoading}
                    isLoading={isLoading}
                    onChange={facebook.updateValue}
                    placeholder="Facebook page"
                    type="text"
                    value={facebook.value}
                  />
                </FormRow>

                <FormRow half padLeft>
                  <FormLabelRow>Instagram</FormLabelRow>
                  <TextInput
                    disabled={isLoading}
                    isLoading={isLoading}
                    onChange={instagram.updateValue}
                    placeholder="Instagram handle"
                    type="text"
                    value={instagram.value}
                  />
                </FormRow>
              </FormRow>

              <FormRow horizontal justifyContent="space-between">
                <FormRow half>
                  <FormLabelRow>Linkedin</FormLabelRow>
                  <TextInput
                    disabled={isLoading}
                    isLoading={isLoading}
                    onChange={linkedIn.updateValue}
                    placeholder="Linkedin page"
                    type="text"
                    value={linkedIn.value}
                  />
                </FormRow>

                <FormRow half padLeft>
                  <FormLabelRow>Twitter</FormLabelRow>
                  <TextInput
                    disabled={isLoading}
                    isLoading={isLoading}
                    onChange={twitter.updateValue}
                    placeholder="Twitter handle"
                    type="text"
                    value={twitter.value}
                  />
                </FormRow>
              </FormRow>

              <FormRow>
                <FormLabelRow>YouTube</FormLabelRow>
                <TextInput
                  disabled={isLoading}
                  isLoading={isLoading}
                  onChange={youtube.updateValue}
                  placeholder="YouTube page"
                  type="text"
                  value={youtube.value}
                />
              </FormRow>
            </Accordion>

            {profile?.isAdmin && (
              <>
                <FormSectionTitle>Admin</FormSectionTitle>

                <FormRow>
                  <FormLabelRow>Visibility</FormLabelRow>
                  <Select
                    disabled={isLoading}
                    onChange={handleVisibilityChange}
                    value={visibility}>
                    <option value={SchoolVisibility.Draft}>Draft</option>
                    <option value={SchoolVisibility.Ready}>Ready</option>
                    <option value={SchoolVisibility.Approved}>Approved</option>
                    <option value={SchoolVisibility.Archived}>Archived</option>
                  </Select>
                </FormRow>
              </>
            )}

            <FormRow horizontal>
              <Button disabled={isLoading} onClick={onSchoolUpdated} secondary>
                Cancel
              </Button>
              <Button disabled={isLoading} onClick={handleSubmit}>
                Save
              </Button>
            </FormRow>

            <FormError
              msg={
                formError
                  ? `Please review your input before submitting: ${formError}`
                  : ''
              }
            />
          </Form>
        </Wrapper>
      </Section>
    </>
  );
}
