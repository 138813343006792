import { School, SchoolMetadata, SchoolMetadataDao, UserProfile } from '@dao';
import { getRegisterRoute, IconButtonLink, Modal } from '@shared';
import { handleGenericError } from '@utils';
import { useState } from 'react';
import {
  FaCopy,
  FaDirections,
  FaFacebook,
  FaHeart,
  FaInstagram,
  FaLinkedin,
  FaRegHeart,
  FaShareAlt,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import styled from 'styled-components';

const SharingLinksWrapper = styled.div<{ isProfile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isProfile ? 'flex-end' : 'flex-start')};
  margin: 0 -5px;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SocialButton = styled.a.attrs({ rel: 'noreferrer', target: '_blank' })<{
  bgColor: string;
}>`
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 14px;
  display: flex;
  height: 28px;
  justify-content: center;
  line-height: initial;
  margin: 5px;
  padding: 0;
  text-align: center;
  width: 28px;

  svg {
    color: ${(props) => props.theme.white.string()};
    max-width: 45%;
    position: relative;
  }
`;

const CopyButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.theme.lightGrey.fade(0.5).string()};
  border: none;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  line-height: initial;
  margin: 5px;
  padding: 0 10px;
  text-align: center;
  transition: background-color 300ms;

  span {
    font-size: 0.7rem;
    margin-left: 8px;
    text-transform: uppercase;
  }

  svg {
    max-width: 45%;
    position: relative;
  }

  &:hover {
    background-color: ${(props) => props.theme.lightGrey.string()};
  }

  &:active {
    background-color: ${(props) => props.theme.cyan.string()};
  }
`;

interface SharingLinksProps {
  inSchoolPage?: boolean;
  profile?: UserProfile;
  school: School;
  schoolMetadata?: SchoolMetadata;
}

export function SharingLinks({
  inSchoolPage,
  profile,
  school,
  schoolMetadata,
}: SharingLinksProps) {
  const links = [];
  const encodedUrl = encodeURIComponent(school.url);
  const [isBookmarked, setBookmarked] = useState(
    schoolMetadata?.isBookmarkedBy(profile?.uid) || false,
  );
  const [isBookmarking, setIsBookmarking] = useState(false);
  const [showSocialSharing, setShowSocialSharing] = useState(false);

  function handleBookmark() {
    if (isBookmarking || !schoolMetadata) {
      return;
    }

    const updatedMetadata = isBookmarked
      ? schoolMetadata.removeBookmarkFor(profile.uid)
      : schoolMetadata.addBookmarkFor(profile.uid);

    setIsBookmarking(true);
    setBookmarked((currBookmarked) => !currBookmarked);
    SchoolMetadataDao.upsertSchoolMetadata(updatedMetadata)
      .then(() => void setIsBookmarking(false))
      .catch(handleGenericError);
  }

  function handleHideSocialSharing() {
    setShowSocialSharing(false);
  }

  function handleShowSocialSharing() {
    setShowSocialSharing(true);
  }

  function handleCopyUrl() {
    navigator?.clipboard?.writeText(school.url);
  }

  if (school.facebookUrl) {
    links.push(
      <IconButtonLink
        href={school.facebookUrl}
        icon={<FaFacebook />}
        inHighlightedSection={inSchoolPage}
        key="facebook"
        rel="noreferrer"
        target="_blank"
      />,
    );
  }

  if (school.instagramUrl) {
    links.push(
      <IconButtonLink
        href={school.instagramUrl}
        icon={<FaInstagram />}
        inHighlightedSection={inSchoolPage}
        key="instagram"
        rel="noreferrer"
        target="_blank"
      />,
    );
  }

  if (school.linkedInUrl) {
    links.push(
      <IconButtonLink
        href={school.linkedInUrl}
        icon={<FaLinkedin />}
        inHighlightedSection={inSchoolPage}
        key="linkedin"
        rel="noreferrer"
        target="_blank"
      />,
    );
  }

  if (school.twitterUrl) {
    links.push(
      <IconButtonLink
        href={school.twitterUrl}
        icon={<FaTwitter />}
        inHighlightedSection={inSchoolPage}
        key="twitter"
        rel="noreferrer"
        target="_blank"
      />,
    );
  }

  if (school.youtubeUrl) {
    links.push(
      <IconButtonLink
        href={school.youtubeUrl}
        icon={<FaYoutube />}
        inHighlightedSection={inSchoolPage}
        key="youtube"
        rel="noreferrer"
        target="_blank"
      />,
    );
  }

  links.push(
    <IconButtonLink
      href={school.directionsUrl}
      icon={<FaDirections />}
      inHighlightedSection={inSchoolPage}
      key="directions"
      rel="noreferrer"
      target="_blank"
    />,
  );

  links.push(
    <IconButtonLink
      copyText={school.route}
      href="#?"
      icon={<FaShareAlt />}
      inHighlightedSection={inSchoolPage}
      key="share"
      onClick={handleShowSocialSharing}
    />,
  );

  const faveIcon = isBookmarked ? <FaHeart /> : <FaRegHeart />;

  if (inSchoolPage) {
    if (profile) {
      if (schoolMetadata) {
        links.push(
          <IconButtonLink
            href="#?"
            icon={faveIcon}
            inHighlightedSection={inSchoolPage}
            key="favorite"
            onClick={handleBookmark}
            red={isBookmarked}
          />,
        );
      }
    } else {
      links.push(
        <IconButtonLink
          href={getRegisterRoute(school.route)}
          icon={faveIcon}
          inHighlightedSection={inSchoolPage}
          key="favorite"
          red={isBookmarked}
        />,
      );
    }
  } else {
    links.push(
      <IconButtonLink
        href="#?"
        icon={faveIcon}
        inHighlightedSection={inSchoolPage}
        key="favorite"
        red={isBookmarked}
      />,
    );
  }

  return (
    <>
      <SharingLinksWrapper isProfile={inSchoolPage}>
        {links}
      </SharingLinksWrapper>
      <Modal
        visible={showSocialSharing}
        onHide={handleHideSocialSharing}
        title={`Share ${school.name}`}>
        <SocialLinks>
          <SocialButton
            bgColor="#4267B2"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}>
            <FaFacebook />
          </SocialButton>
          <SocialButton
            bgColor="#0077b5"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`}>
            <FaLinkedin />
          </SocialButton>
          <SocialButton
            bgColor="#55acee"
            href={`http://www.twitter.com/share?url=${encodedUrl}`}>
            <FaTwitter />
          </SocialButton>
          <CopyButton onClick={handleCopyUrl}>
            <FaCopy />
            <span>copy link</span>
          </CopyButton>
        </SocialLinks>
      </Modal>
    </>
  );
}
