import { fetch } from 'cross-fetch';
import { SchoolSearchSuggestions } from './types';

export async function getSchoolSearchSuggestions(
  query: string,
): Promise<SchoolSearchSuggestions> {
  const response = await fetch(`/api/location-autocomplete?q=${query}`);

  if (response.status !== 200) {
    return [];
  }

  const suggestions: string[] = await response.json();

  return suggestions.map((value) => ({ value }));
}
