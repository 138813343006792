import { School } from '@dao';
import { ResultCard } from '@shared';
import styled from 'styled-components';

export interface PromotedSchoolProps {
  school: School;
}

const VideoEmbed = styled.iframe`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`;

const SchoolName = styled.span`
  border-radius: 4px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  padding: 0 5px;
  text-transform: uppercase;
`;

const SchoolLocation = styled.span``;

const PromotedSchoolWrapper = styled(ResultCard)<{
  hasVideoEmbed: boolean;
}>`
  align-items: center;
  background-color: ${(props) =>
    props.theme.yellow.fade(props.hasVideoEmbed ? 0.5 : 0.25).string()};
  flex-direction: column;
  height: 140px;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  text-align: center;
  width: 249px;

  &:active,
  &:focus,
  &:hover {
    background-color: ${(props) => props.theme.yellow.string()};
    box-shadow: 0 0 30px
      ${(props) => props.theme.grey.mix(props.theme.white, 0.75).string()};
  }

  ${SchoolName} {
    background-color: ${(props) =>
      props.hasVideoEmbed ? props.theme.yellow.string() : 'transparent'};
  }
`;

export function PromotedSchool({ school }: PromotedSchoolProps) {
  return (
    <PromotedSchoolWrapper hasVideoEmbed={!!school.videoEmbedUrl}>
      {school.videoEmbedUrl && (
        <VideoEmbed
          allow="autoplay; encrypted-media;"
          frameBorder="0"
          src={school.videoEmbedUrl}
          title={school.name}></VideoEmbed>
      )}

      <SchoolName>{school?.name}</SchoolName>
      <SchoolLocation>{school?.location}</SchoolLocation>
    </PromotedSchoolWrapper>
  );
}
