import { UserProfile, UserProfileDao } from '@dao';
import { useEffect, useState } from 'react';

export function useSchoolOwners(
  profile: UserProfile,
  profileIds: string[],
): UserProfile[] {
  const [profiles, setProfiles] = useState<UserProfile[]>([]);

  useEffect(() => {
    if (!profile?.isAdmin || profileIds.length < 1) {
      return;
    }

    UserProfileDao.listUserProfilesById(profileIds)
      .then(setProfiles)
      .catch((cause) => {
        throw new Error('Could not retrieve school owners', { cause });
      });
  }, [profile?.isAdmin, profileIds]);

  return profiles;
}
