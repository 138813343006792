import { STATES_BY_CODE } from '@utils';
import { FaArrowLeft } from 'react-icons/fa';
import { SchoolSearchFilters } from '../../search/filters/SchoolSearchFilters';
import { Link } from './Link';

interface BackProps {
  stateCode?: string;
}

export function Back({ stateCode }: BackProps) {
  if (!(stateCode in STATES_BY_CODE)) {
    return null;
  }

  const name = STATES_BY_CODE[stateCode];
  const href = SchoolSearchFilters.forState(stateCode).route;
  const text = `Find more schools in ${name}`;

  return <Link href={href} icon={<FaArrowLeft />} text={text} />;
}
