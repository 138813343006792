import { ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';
import { SchoolSearchFilters } from '../search/filters/SchoolSearchFilters';

/** Hook for generating a SchoolSearchFilter based on URL parameters. */
export function useSchoolSearchFiltersFromUrlParams(query: ParsedUrlQuery) {
  const [filters, setFilters] = useState<SchoolSearchFilters>(
    SchoolSearchFilters.BLANK,
  );
  const [isLoadingFilters, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    SchoolSearchFilters.fromUrlParams(query).then((updated) => {
      setFilters(updated);
      setLoading(false);
    });
  }, [query]);

  return { filters, isLoadingFilters };
}
