import { FirestorePage } from '@libraries/firebase';
import {
  CityData,
  formatStateCode,
  getQueryParamValue,
  STATES_BY_CODE,
} from '@utils';
import { ParsedUrlQuery } from 'querystring';
import {
  getSchoolAttributeKeysFromParams,
  getSchoolAttributes,
} from '../../utils';
import { getMaxTuitionFromParams } from '../../utils/getMaxTuitionFromParams';
import { getSearchQueryKeywords } from '../getSearchQueryKeywords';
import { SchoolSearchFilterOptions } from './types';

/**
 * Defines the parameters for searching schools.
 */
export class SchoolSearchFilters {
  static BLANK = SchoolSearchFilters.create('', {});

  /**
   * Creates a new search filter instance.
   */
  static create(
    query: string,
    options: SchoolSearchFilterOptions,
    page?: FirestorePage,
    limit?: number,
  ) {
    return new SchoolSearchFilters(
      query.trim(),
      { ...options },
      page ?? FirestorePage.FIRST,
      limit ?? 8,
    );
  }

  /**
   * Creates a search filter using the given state code.
   */
  static forState(
    rawCode: string,
    options?: SchoolSearchFilterOptions,
    page?: FirestorePage,
  ) {
    const state = formatStateCode(rawCode);

    // If state code isn't supported, return default search filters.
    if (!(state in STATES_BY_CODE)) {
      return SchoolSearchFilters.create(rawCode.trim(), options, page);
    }

    const name = STATES_BY_CODE[state];

    return SchoolSearchFilters.create(
      name,
      {
        ...options,
        state,
        city: null,
        zip: null,
      },
      page,
    );
  }

  /**
   * Creates a search filter using the given city object.
   */
  static forCity(
    { city, state }: CityData,
    options?: SchoolSearchFilterOptions,
    page?: FirestorePage,
  ) {
    return SchoolSearchFilters.create(
      `${city}, ${state}`,
      {
        ...options,
        city,
        state,
        zip: null,
      },
      page,
    );
  }

  /**
   * Creates a search filter using the given ZIP code.
   */
  static forZip(
    zip: string,
    options?: SchoolSearchFilterOptions,
    page?: FirestorePage,
  ) {
    return SchoolSearchFilters.create(
      zip,
      {
        ...options,
        zip,
        city: null,
        state: null,
      },
      page,
    );
  }

  /**
   * Creates a new search filter by parsing the search query for keywords.
   *
   * In other words, if the search query is "Texas", this will create a search
   * filter with the "Texas" keyword and "state: TX" option.
   */
  static async parseQuery(
    query: string,
    options?: SchoolSearchFilterOptions,
    page?: FirestorePage,
  ) {
    const keywords = await getSearchQueryKeywords(query);

    if (keywords?.city) {
      return SchoolSearchFilters.forCity(keywords as CityData, options, page);
    }

    if (keywords?.state) {
      return SchoolSearchFilters.forState(keywords.state, options, page);
    }

    if (keywords?.zip) {
      return SchoolSearchFilters.forZip(keywords.zip, options, page);
    }

    return SchoolSearchFilters.create(query, options, page);
  }

  /** Creates a SchoolSearchFilters based on URL query params. */
  static async fromUrlParams(params: ParsedUrlQuery) {
    const query = getQueryParamValue(params.q);
    const page = FirestorePage.decode(getQueryParamValue(params.p));
    const attributes = getSchoolAttributeKeysFromParams(params);
    const options: SchoolSearchFilterOptions = {
      maxTuition: getMaxTuitionFromParams(params),
    };

    if (attributes.length < 1) {
      return this.parseQuery(query, options, page);
    }

    for (const key of attributes) {
      options[key] = true;
    }

    return this.parseQuery(query, options, page);
  }

  get city() {
    return this.options.city ?? '';
  }

  get state() {
    return this.options.state;
  }

  get zip() {
    return this.options.zip ?? '';
  }

  get hasDayClasses() {
    return !!this.options.hasDayClasses;
  }

  get hasEveningClasses() {
    return !!this.options.hasEveningClasses;
  }

  get hasOfflineClasses() {
    return !!this.options.hasOfflineClasses;
  }

  get hasOnlineClasses() {
    return !!this.options.hasOnlineClasses;
  }

  get hasWeekendClasses() {
    return !!this.options.hasWeekendClasses;
  }

  get isVerified() {
    return !!this.options.isVerified;
  }

  get maxTuition() {
    return this.options.maxTuition;
  }

  get route() {
    const url = new URL('https://example.com/schools');

    if (this.query.length) {
      url.searchParams.append('q', this.query);
    }

    getSchoolAttributes(this.options).forEach((attribute) => {
      url.searchParams.append(attribute, '1');
    });

    if (this.options.maxTuition) {
      url.searchParams.append('t', this.options.maxTuition.toString());
    }

    if (this.page.isValid) {
      url.searchParams.append('p', this.page.toString());
    }

    const route = `${url.pathname}?${url.searchParams.toString()}`;

    return route.endsWith('?') ? route.substring(0, route.length - 1) : route;
  }

  /** Unique hash representing this set of filters. */
  get hash() {
    return this.toString();
  }

  /** Returns a new search filter with the provided limit. */
  withLimit(limit: number) {
    return SchoolSearchFilters.create(
      this.query,
      this.options,
      this.page,
      limit,
    );
  }

  /** Returns a new search filter with the provided options. */
  withOptions(options: SchoolSearchFilterOptions) {
    return SchoolSearchFilters.create(this.query, {
      ...options,
      city: this.options.city,
      state: this.options.state,
      zip: this.options.zip,
    });
  }

  /** Returns a new search filter with the provided page cursor. */
  withPage(page: FirestorePage) {
    return page.isValid && !page.isEqual(this.page)
      ? SchoolSearchFilters.create(this.query, this.options, page)
      : this;
  }

  /** Returns a new search filter with the provided query. */
  async withQuery(query: string) {
    return SchoolSearchFilters.parseQuery(query, this.options);
  }

  /** Unique hash representing this set of filters. */
  toString() {
    return [
      this.query,
      this.city,
      this.state,
      this.zip,
      this.hasDayClasses.toString(),
      this.hasEveningClasses.toString(),
      this.hasOfflineClasses.toString(),
      this.hasOnlineClasses.toString(),
      this.hasWeekendClasses.toString(),
      this.isVerified.toString(),
      this.maxTuition,
      this.page.toString(),
    ].join();
  }

  constructor(
    // Search query.
    readonly query: string,
    // Search filters.
    readonly options: SchoolSearchFilterOptions,
    // Pagination cursor.
    readonly page: FirestorePage,
    // Query limit.
    readonly limit: number = 8,
  ) {}
}
