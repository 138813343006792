import { Address } from '@utils';
import { useState } from 'react';

export function useAddressSelector(
  initialAddress?: Address,
  initialCityState = '',
  initialText = '',
) {
  const [address, setAddress] = useState<Address>(initialAddress || null);
  const [addressErr, setAddressErr] = useState('');
  const [cityState, setCityState] = useState(initialCityState);
  const [cityStateErr, setCityStateErr] = useState('');
  const [text, setText] = useState(initialText);
  const [zipError, setZipError] = useState('');

  function clear() {
    setAddress(null);
    setAddressErr('');
    setCityState('');
    setCityStateErr('');
    setText('');
    setZipError('');
  }

  function selectAddress(selectedAddress: Address) {
    setAddress(selectedAddress);
    setAddressErr('');
    setCityState(`${selectedAddress.city}, ${selectedAddress.state}`);
    setCityStateErr('');
    setText(selectedAddress.address);
  }

  function selectCityState(value: string) {
    const [city, state] = value.split(', ');

    setCityState(value);
    setCityStateErr('');
    setAddress((currAddress) => ({ ...currAddress, city, state }));
  }

  function updateCityState(value: string) {
    setCityState(value);
    setCityStateErr('');
    setAddress((currAddress) => ({ ...currAddress, city: value, state: '' }));
  }

  function updateAddressError(error: string) {
    setAddressErr(error);
  }

  function updateCityStateError(error: string) {
    setCityStateErr(error);
  }

  function updateText(value: string) {
    setAddressErr('');
    setText(value);
  }

  function updateZip(value: string) {
    setAddress((currAddress) => ({ ...currAddress, zip: value }));
  }

  function updateZipError(error: string) {
    setZipError(error);
  }

  return {
    address,
    addressErr,
    cityState,
    cityStateErr,
    clear,
    selectAddress,
    selectCityState,
    text,
    updateAddressError,
    updateCityState,
    updateCityStateError,
    updateText,
    updateZip,
    updateZipError,
    zipError,
  };
}
