import { Card, getLogInRoute, Link, Section } from '@shared';
import { getCurrentPath } from '@utils';
import { useRouter } from 'next/router';
import styled from 'styled-components';

interface SchoolNotFoundProps {
  isLoggedIn: boolean;
}

const SchoolNotFoundWrapper = styled(Card)`
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 3rem;
  text-align: center;
`;

export function SchoolNotFound({ isLoggedIn }: SchoolNotFoundProps) {
  const router = useRouter();
  const logInRoute = getLogInRoute(getCurrentPath(router));

  return (
    <Section>
      <SchoolNotFoundWrapper>
        School not found.
        {!isLoggedIn && (
          <span>
            {' '}
            You might have to <Link href={logInRoute}>log in first</Link>.
          </span>
        )}
      </SchoolNotFoundWrapper>
    </Section>
  );
}
