import {
  Button,
  Card,
  Checkbox,
  FormLabelRow,
  FormTitle,
  Range,
} from '@shared';
import { useState } from 'react';
import styled from 'styled-components';
import { SchoolSearchFilterOptions } from '../search/filters/types';
import {
  BOOLEAN_SCHOOL_ATTRIBUTE_LIST,
  getSchoolAttributeKey,
  getSchoolAttributeName,
} from '../utils';
import { BooleanAttributesWrapper } from './BooleanAttributesWrapper';
import { TUITION_MAX, TUITION_MIN } from './constants';
import { renderTuitionText } from './renderTuitionText';

const ExpandedFiltersWrapper = styled(Card)``;

const TuitionWrapper = styled.div`
  display: block;
  max-width: 350px;

  ${FormLabelRow} {
    padding: 0.4rem 0 0;
  }
`;

interface ExpandedFiltersProps {
  onUpdateOptions: (options: SchoolSearchFilterOptions) => void;
  options: SchoolSearchFilterOptions;
}

export function ExpandedFilters({
  onUpdateOptions,
  options,
}: ExpandedFiltersProps) {
  const [filters, setFilters] = useState<SchoolSearchFilterOptions>(options);

  function handleUpdateFilters() {
    onUpdateOptions(filters);
  }

  function handleUpdateTuition(maxTuition: number) {
    setFilters(() => ({ ...filters, maxTuition: maxTuition * 100 }));
  }

  return (
    <ExpandedFiltersWrapper>
      <FormTitle>Search filters</FormTitle>

      <BooleanAttributesWrapper>
        {BOOLEAN_SCHOOL_ATTRIBUTE_LIST.map((attribute) => {
          const key = getSchoolAttributeKey(attribute);
          const label = getSchoolAttributeName(attribute);
          const active = !!filters[key];

          function handleChange(newValue: boolean) {
            setFilters((currFilters) => ({ ...currFilters, [key]: newValue }));
          }

          return (
            <Checkbox
              checked={active}
              key={key}
              label={label}
              name={attribute}
              onChange={handleChange}
            />
          );
        })}
      </BooleanAttributesWrapper>

      <TuitionWrapper>
        <FormLabelRow>Tuition</FormLabelRow>
        <Range
          min={TUITION_MIN / 100}
          max={TUITION_MAX / 100}
          fixedWidth="200px"
          onChange={handleUpdateTuition}
          renderLabel={renderTuitionText}
          step={250}
          value={(filters.maxTuition ?? TUITION_MAX) / 100}
        />
      </TuitionWrapper>

      <Button onClick={handleUpdateFilters}>Update filters</Button>
    </ExpandedFiltersWrapper>
  );
}
