import { ParsedUrlQuery } from 'querystring';
import { SchoolAttribute, SCHOOL_ATTRIBUTE_LIST } from './list';

/**
 * Builds a list of attributes for the given URL params.
 */
export function getSchoolAttributesFromParams(
  params: ParsedUrlQuery,
): SchoolAttribute[] {
  const attributes = [];

  for (const attr of SCHOOL_ATTRIBUTE_LIST) {
    if (attr in params) {
      attributes.push(attr);
    }
  }

  return attributes;
}
