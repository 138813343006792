import { STATES_BY_CODE, STATES_BY_NAME } from '@utils';

interface SearchQueryKeywords {
  city?: string;
  state?: string;
  zip?: string;
}

/**
 * Retrieves keywords from a search query.
 */
export async function getSearchQueryKeywords(
  query: string,
): Promise<SearchQueryKeywords | null> {
  if (query in STATES_BY_NAME) {
    return Promise.resolve({ state: STATES_BY_NAME[query] });
  }

  if (query in STATES_BY_CODE) {
    return Promise.resolve({ state: query });
  }

  if (/[0-9]{5}/.test(query)) {
    return Promise.resolve({ zip: query });
  }

  if (query.trim().length < 1) {
    return Promise.resolve(null);
  }

  // Search by city name.
  const response = await fetch(`/api/city-name-search?q=${query}`);

  if (response.status === 200) {
    return response.json();
  }

  return Promise.resolve(null);
}
