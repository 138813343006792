import { FormSectionTitle } from '@shared';
import styled from 'styled-components';

interface InfoCardProps {
  children: React.ReactNode;
  title: string;
}

const InfoCardWrapper = styled.div`
  margin: 1rem;

  a {
    color: ${(props) => props.theme.backgroundColor.string()};
  }

  ${FormSectionTitle} {
    color: ${(props) =>
      props.theme.textColor.mix(props.theme.white, 0.8).string()};
    margin-bottom: 0.6rem;
  }
`;

export function InfoCard({ children, title }: InfoCardProps) {
  return (
    <InfoCardWrapper>
      <FormSectionTitle>{title}</FormSectionTitle>
      {children}
    </InfoCardWrapper>
  );
}
