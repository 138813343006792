import NextLink from 'next/link';
import styled from 'styled-components';

const Anchor = styled.a`
  background-color: ${(props) => props.theme.white.fade(0.9).string()};
  border-radius: 4px;
  color: ${(props) => props.theme.white.string()};
  display: inline-block;
  margin: 1rem;
  padding: 0.7rem 1rem;
  text-decoration: none;
  transition: background-color 300ms;

  &:hover {
    background-color: ${(props) => props.theme.white.fade(0.8).string()};
  }

  svg {
    display: none;
    width: ${(props) => props.theme.iconSize};

    @media (min-width: 800px) {
      display: inline-block;
      margin-right: 0.8rem;
    }
  }
`;

interface LinkProps {
  href: string;
  icon?: JSX.Element;
  text: string;
}

export function Link({ href, icon, text }: LinkProps) {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <Anchor>
        {icon ? icon : null}
        <span>{text}</span>
      </Anchor>
    </NextLink>
  );
}
