import { School, SchoolVisibility, UserProfile } from '@dao';
import { getPromoteRoute } from '@shared';
import { Link } from './Link';

interface PromoteProps {
  profile?: UserProfile;
  school?: School;
}

export function Promote({ profile, school }: PromoteProps) {
  // We don't have the required info.
  if (!profile || !school) {
    return null;
  }

  // The user is not an editor.
  if (!school.editors.includes(profile.uid)) {
    return null;
  }

  // The school is not in a promotable state.
  if (school.visibility !== SchoolVisibility.Approved) {
    return null;
  }

  // The school is missing crucial information.
  if (!school.isPublishable) {
    return null;
  }

  return <Link href={getPromoteRoute(school.id)} text="Promote" />;
}
