import { School, SchoolVisibility, UserProfile } from '@dao';
import { Attribute, AttributesWrapper } from './Attribute';

interface UserProfileHintsProps {
  profile?: UserProfile;
  school?: School;
}

export function UserProfileHints({ profile, school }: UserProfileHintsProps) {
  if (!profile || !school) {
    return null;
  }

  return (
    <AttributesWrapper>
      {school.visibility === SchoolVisibility.Draft && (
        <Attribute yellow>Draft</Attribute>
      )}
      {school.visibility === SchoolVisibility.Ready && (
        <Attribute yellow>Pending approval</Attribute>
      )}
      {school.visibility === SchoolVisibility.Archived && (
        <Attribute yellow>Archived</Attribute>
      )}
    </AttributesWrapper>
  );
}
