import { School, SchoolClaim, SchoolVisibility, UserProfile } from '@dao';
import { Pill } from '@shared';
import styled from 'styled-components';

const HintsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto;
  width: 100%;

  @media (min-width: 800px) {
    justify-content: flex-start;
    margin: 1rem;
  }
`;

interface HintsProps {
  pendingClaims: SchoolClaim[];
  profile?: UserProfile;
  school?: School;
}

export function Hints({ pendingClaims, profile, school }: HintsProps) {
  if (!profile || !school) {
    return null;
  }

  if (pendingClaims.find((claim) => claim.profileId === profile.uid)) {
    return (
      <HintsWrapper>
        <Pill autoMargin inHighlightedSection yellow>
          Claim pending approval
        </Pill>
      </HintsWrapper>
    );
  }

  if (!school.editors.includes(profile.uid) && !profile.isAdmin) {
    return null;
  }

  return (
    <HintsWrapper>
      {school.visibility === SchoolVisibility.Draft && (
        <Pill autoMargin inHighlightedSection yellow>
          Draft
        </Pill>
      )}
      {school.visibility === SchoolVisibility.Ready && (
        <Pill autoMargin inHighlightedSection yellow>
          Pending approval
        </Pill>
      )}
      {school.visibility === SchoolVisibility.Archived && (
        <Pill autoMargin inHighlightedSection yellow>
          Archived
        </Pill>
      )}
    </HintsWrapper>
  );
}
