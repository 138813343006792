import { ParsedUrlQuery } from 'querystring';
import { getSchoolAttributeKey } from './getSchoolAttributeKey';
import { getSchoolAttributesFromParams } from './getSchoolAttributesFromParams';
import { SchoolAttributeKey } from './keyAttributeMap';

/**
 * Gets the list of attribute names for the given URL params.
 */
export function getSchoolAttributeKeysFromParams(
  params: ParsedUrlQuery,
): SchoolAttributeKey[] {
  return getSchoolAttributesFromParams(params).map(getSchoolAttributeKey);
}
