import {
  School,
  SchoolDao,
  SchoolMetadata,
  SchoolMetadataDao,
  SchoolWithMetadata,
  SerializedSchool,
  SerializedSchoolMetadata,
} from '@dao';
import { logError } from '@shared';
import { useEffect, useState } from 'react';

interface SchoolResult {
  isLoading: boolean;
  school: SchoolWithMetadata;
}

/** React hook for fetching a school record. */
export function useSchool(
  id: SerializedSchool | string,
  serializedSchoolMetadata?: SerializedSchoolMetadata,
): SchoolResult {
  // If we have serialized data, use it for the initial state.
  const initSchool =
    id && typeof id === 'object' ? School.fromSerialized(id) : null;
  const initSchoolMetadata = serializedSchoolMetadata
    ? SchoolMetadata.fromSerialized(serializedSchoolMetadata)
    : null;
  const [school, setSchool] = useState<School>(initSchool);
  const [metadata, setMetadata] = useState<SchoolMetadata>(initSchoolMetadata);
  const [isLoading, setLoading] = useState(!initSchool);

  useEffect(() => {
    if (typeof id !== 'string') {
      return;
    }

    // If we have a string ID, retrieve the school from the database.
    setLoading(true);
    SchoolDao.getSchool(id)
      .then((schoolResult) => {
        setSchool(schoolResult);
        SchoolMetadataDao.getSchoolMetadata(schoolResult?.id).then(
          (metadataResult) => {
            setMetadata(metadataResult);
            setLoading(false);
          },
        );
      })
      .catch((error) => {
        logError(error);
        setLoading(false);
      });
  }, [id, serializedSchoolMetadata]);

  return { isLoading, school: { school, metadata } };
}
