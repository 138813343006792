import { School, SchoolMetadata, useCloudFile, UserProfile } from '@dao';
import { ResultCard } from '@shared';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { FaSchool } from 'react-icons/fa';
import styled from 'styled-components';
import { SharingLinks } from '../../SharingLinks';
import { getSchoolAttributeNames } from '../../utils';
import { Attribute, AttributesWrapper } from './Attribute';
import { UserProfileHints } from './UserProfileHints';

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0.4rem;
`;

const Location = styled.span`
  line-height: 2rem;
  margin-right: 1rem;
`;

const Logo = styled.div`
  color: ${(props) => props.theme.lightGrey.string()};
  font-size: 2rem;
  margin-right: 1rem;
  width: 50px;
`;

const Name = styled.div`
  font-weight: ${(props) => props.theme.fontWeightBold};
  line-height: 1.8rem;
  text-align: left;
`;

const SchoolDetails = styled.div`
  flex-grow: 1;
`;

export interface ResultProps {
  profile?: UserProfile;
  school: School;
  schoolMetadata?: SchoolMetadata;
}

/** Renders a horizontal school card. */
export function SchoolCard({ profile, school, schoolMetadata }: ResultProps) {
  const router = useRouter();
  const attributes = getSchoolAttributeNames(school);

  function onClick() {
    router.push(school.route);
  }

  const { blobUrl } = useCloudFile(school.publicBasePath, school.logo);

  return (
    <ResultCard onClick={onClick}>
      <Logo>
        {blobUrl ? (
          <Image alt="" height={50} src={blobUrl} width={50} />
        ) : (
          <FaSchool />
        )}
      </Logo>

      <SchoolDetails>
        <Name>{school.name}</Name>
        <Info>
          <Location>{school.location}</Location>
          <AttributesWrapper>
            {attributes.map((attribute) => (
              <Attribute key={attribute}>{attribute}</Attribute>
            ))}
          </AttributesWrapper>
          <UserProfileHints profile={profile} school={school} />
        </Info>
        <SharingLinks
          profile={profile}
          school={school}
          schoolMetadata={schoolMetadata}
        />
      </SchoolDetails>
    </ResultCard>
  );
}
