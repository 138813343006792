import { UserProfile } from '@dao';
import { Link } from '@shared';
import styled from 'styled-components';
import { useSchoolOwners } from '../hooks/useSchoolOwners';
import { InfoCard } from './InfoCard';

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function getName(profile: UserProfile) {
  return profile.name === profile.data.email
    ? profile.data.email
    : `${profile.name} (${profile.data.email})`;
}

interface SchoolOwnersProps {
  editors: string[];
  profile: UserProfile;
}

export function SchoolOwners({ editors, profile }: SchoolOwnersProps) {
  const owners = useSchoolOwners(profile, editors);

  return (
    <InfoCard title="School Owners">
      <EmailWrapper>
        {owners.map((owner) => (
          <Link
            href={`mailto:${owner.data.email}`}
            inHighlightedSection
            key={owner.uid}
            target="_blank">
            {getName(owner)}
          </Link>
        ))}
      </EmailWrapper>
    </InfoCard>
  );
}
