import {
  School,
  SchoolClaim,
  SchoolMetadata,
  Subscription,
  UserProfile,
} from '@dao';
import { Card, Loading, Section } from '@shared';
import { costToString } from '@utils';
import { useState } from 'react';
import styled from 'styled-components';
import { Metadata } from './admin/Metadata';
import { SchoolProfileDetails } from './details/Details';
import { SchoolProfileMap } from './map/Map';
import { TopNav } from './nav/TopNav';
import { SchoolNotFound } from './SchoolNotFound';

const ProgramWrapper = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  width: 100%;
`;

const ProgramName = styled.h2`
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

const ProgramTuition = styled.p`
  color: ${(props) => props.theme.navy.string()};
  font-weight: ${(props) => props.theme.fontWeightBold};
`;

const MapWrapper = styled.div`
  height: 100vh;
  max-height: 500px;
  min-height: 300px;
`;

interface SchoolProfileProps {
  isLoading: boolean;
  pendingClaims: SchoolClaim[];
  profile: UserProfile;
  school: School;
  schoolMetadata: SchoolMetadata;
  subscription: Subscription;
}

export function SchoolProfile({
  pendingClaims,
  isLoading,
  profile,
  school,
  schoolMetadata,
  subscription,
}: SchoolProfileProps) {
  /* The [schoolWithEdits] state is the school from Firebase plus any edits made
  on the page. */
  const [schoolWithEdits, setSchoolWithEdits] = useState<School | null>(null);

  // Once the school is loaded, update the [schoolWithEdits] state.
  if (school && !schoolWithEdits) {
    setSchoolWithEdits(school);
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!school) {
    return <SchoolNotFound isLoggedIn={!!profile} />;
  }

  return (
    <>
      <Section highlight>
        {profile?.isAdmin ? (
          <Metadata
            profile={profile}
            school={schoolWithEdits}
            subscription={subscription}
          />
        ) : null}
        <TopNav
          pendingClaims={pendingClaims}
          profile={profile}
          school={schoolWithEdits}
        />
        <SchoolProfileDetails
          profile={profile}
          school={schoolWithEdits}
          schoolMetadata={schoolMetadata}
          subscription={subscription}
          updateSchoolState={setSchoolWithEdits}
        />
      </Section>
      {school.programs.length ? (
        <Section>
          <ProgramWrapper>
            {school.programs.map((program) => (
              <Card key={program.name}>
                <ProgramName>{program.name}</ProgramName>
                <p>{program.description}</p>
                <ProgramTuition>{costToString(program.tuition)}</ProgramTuition>
              </Card>
            ))}
          </ProgramWrapper>
        </Section>
      ) : null}
      <MapWrapper>
        <SchoolProfileMap school={schoolWithEdits} />
      </MapWrapper>
    </>
  );
}
