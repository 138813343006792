import {
  School,
  SchoolDao,
  SchoolMetadata,
  Subscription,
  UserProfile,
} from '@dao';
import {
  Contact,
  ContactAnchor,
  ImageUpload,
  Link,
  Name,
  Pill,
  ProfileDetails,
  ProfileDetailsWrapper,
} from '@shared';
import styled from 'styled-components';
import { SharingLinks } from '../../SharingLinks';
import {
  BOOLEAN_SCHOOL_ATTRIBUTE_LIST,
  getSchoolAttributeKey,
  getSchoolAttributeName,
} from '../../utils';
import { WebsiteLink } from './WebsiteLink';

interface SchoolProfileDetailsProps {
  profile?: UserProfile;
  school: School;
  schoolMetadata: SchoolMetadata;
  subscription?: Subscription;
  updateSchoolState: (school: School) => void;
}

const AttributesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.4rem auto 1rem;

  @media (min-width: 1024px) {
    justify-content: flex-start;
    text-align: left;
  }
`;

const VideoEmbed = styled.iframe`
  height: 200px;
  width: 100%;
`;

export function SchoolProfileDetails({
  profile,
  school,
  schoolMetadata,
  subscription,
  updateSchoolState,
}: SchoolProfileDetailsProps) {
  function deleteSchoolLogo() {
    const updatedSchool = school.withSchoolData({ logo: '' });

    SchoolDao.updateSchool(updatedSchool);
    updateSchoolState(updatedSchool);
  }

  function uploadSchoolLogo(logo: string) {
    const updatedSchool = school.withSchoolData({ logo });

    SchoolDao.updateSchool(updatedSchool);
    updateSchoolState(updatedSchool);
  }

  return (
    <ProfileDetailsWrapper>
      <ProfileDetails>
        <Name>{school.name}</Name>
        <AttributesWrapper>
          {BOOLEAN_SCHOOL_ATTRIBUTE_LIST.map((attribute) => {
            const key = getSchoolAttributeKey(attribute);

            if (!school[key]) {
              return null;
            }

            const label = getSchoolAttributeName(attribute);

            return (
              <Pill key={label} inHighlightedSection>
                {label}
              </Pill>
            );
          })}
        </AttributesWrapper>
        {school.description && <div>{school.description}</div>}
      </ProfileDetails>

      <Contact>
        {(school.editors.includes(profile?.uid) || profile?.isAdmin) && (
          <ImageUpload
            alt="School Logo"
            basePath={school.publicBasePath}
            emptyText="Upload your school logo"
            filename={school.logo}
            onDelete={deleteSchoolLogo}
            onUpload={uploadSchoolLogo}
          />
        )}

        {subscription?.isActive() && school.data.videoEmbedUrl && (
          <VideoEmbed
            allow="autoplay; encrypted-media;"
            frameBorder="0"
            src={school.data.videoEmbedUrl}
            title={school.name}
          />
        )}
        {school.phone && (
          <ContactAnchor href={`tel:${school.data.phone}`}>
            {school.phone}
          </ContactAnchor>
        )}
        {school.address && (
          <ContactAnchor
            href={school.directionsUrl}
            rel="noreferrer"
            target="_blank">
            {school.address}
          </ContactAnchor>
        )}
        {school.location && <span>{school.location}</span>}
        {school.websiteUrl && <WebsiteLink url={school.websiteUrl} />}
        {school.email ? (
          <Link
            href={`mailto:${school.email}`}
            inHighlightedSection
            target="_blank">
            {school.email}
          </Link>
        ) : null}
        <SharingLinks
          inSchoolPage
          profile={profile}
          school={school}
          schoolMetadata={schoolMetadata}
        />
      </Contact>
    </ProfileDetailsWrapper>
  );
}
