import { School, SchoolDao } from '@dao';
import { handleGenericError } from '@utils';
import { useEffect, useState } from 'react';

interface SchoolResults {
  isLoading: boolean;
  schools: School[];
}

/** React hook for retrieving schools by name. */
export function useSchoolListByName(
  name: string,
  hasFocus: boolean,
): SchoolResults {
  const [isLoading, setLoading] = useState(true);
  const [schools, setSchools] = useState<School[]>([]);

  useEffect(() => {
    if (!name || hasFocus) {
      return;
    }

    setLoading(true);
    SchoolDao.listSchoolsByName(name)
      .then((schools) => {
        setSchools(schools);
        setLoading(false);
      })
      .catch(handleGenericError);
  }, [name, hasFocus]);

  return { isLoading, schools };
}
