import {
  SchoolClaim,
  SchoolClaimDao,
  SchoolClaimFilter,
  UserProfile,
} from '@dao';
import { logError } from '@shared';
import { useEffect, useState } from 'react';
import { SchoolClaimStatus } from 'src/dao/entities/schoolClaim';

interface ListSchoolClaimsResult {
  isLoading: boolean;
  claims: SchoolClaim[];
}

/** React hook for fetching list of school claims. */
export function useClaims(
  profile: UserProfile,
  schoolId: string,
  status: SchoolClaimStatus = null,
): ListSchoolClaimsResult {
  const [claims, setClaims] = useState<SchoolClaim[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!profile || !schoolId) {
      return;
    }

    setLoading(true);
    SchoolClaimDao.listSchoolClaims(
      SchoolClaimFilter.forSchool(schoolId, status),
    )
      .then((results) => setClaims(results))
      .catch((error) => logError(error))
      .finally(() => setLoading(false));
  }, [profile, schoolId, status]);

  return { isLoading, claims };
}
