import { getQueryParamValue } from '@utils';
import { ParsedUrlQuery } from 'querystring';
import { TUITION_MAX, TUITION_MIN } from '../filters/constants';

/** Retrieves the tuition value from a set of query parameters. */
export function getMaxTuitionFromParams(params?: ParsedUrlQuery): number {
  const tuitionStr = getQueryParamValue(params?.t);
  const tuition = parseInt(tuitionStr.replace(/[^0-9]/, ''), 10);

  if (isNaN(tuition)) {
    return null;
  }

  return tuition >= TUITION_MIN && tuition < TUITION_MAX ? tuition : null;
}
