import { Card, FormTitle } from '@shared';
import styled from 'styled-components';
import { SchoolSearchInputRow } from '../search/SearchInputRow';

const SearchFormWrapper = styled(Card)`
  margin-bottom: 1rem;
  text-align: left;
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.grey.string()};
`;

interface SearchFormProps {
  onSubmit: (value: string) => void;
  query: string;
}

export function SearchForm({ onSubmit, query }: SearchFormProps) {
  return (
    <SearchFormWrapper>
      <FormTitle>Directory</FormTitle>
      <SubTitle>Search thousands of schools</SubTitle>
      <SchoolSearchInputRow initialQuery={query} onSubmit={onSubmit} />
    </SearchFormWrapper>
  );
}
