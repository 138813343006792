import styled, { ThemeProps } from 'styled-components';

interface AttributeProps {
  yellow?: boolean;
}

type ThemedAttributeProps = ThemeProps<AttributeProps>;

function getColor(props: ThemedAttributeProps) {
  if (props.yellow) {
    return props.theme.yellow.mix(props.theme.white, 0.8).string();
  }

  return props.theme.cyan.mix(props.theme.white, 0.8).string();
}

export const AttributesWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

export const Attribute = styled.span<AttributeProps>`
  background-color: ${getColor};
  border-radius: 4px;
  color: ${(props) => props.theme.navy.string()};
  font-size: 0.7rem;
  height: 1.1rem;
  line-height: 1.1rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.1rem 0.3rem;
  text-transform: uppercase;
`;
