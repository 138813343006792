import { Button, FormRow, SearchInput } from '@shared';
import { KeyboardEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSchoolSearchSuggestions } from './suggestions/useSchoolSearchSuggestions';

const SchoolSearchInputRowWrapper = styled(FormRow).attrs({
  justifyContent: 'flex-start',
  maxWidth: '100%',
})`
  flex-direction: column;

  & > div {
    margin-right: 0;
  }

  input {
    margin: 0.4rem 0;
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 800px) {
    flex-direction: row;

    & > div {
      margin-right: 1rem;
    }
  }
`;

interface SchoolSearchInputRowProps {
  initialQuery?: string;
  onSubmit: (value: string) => void;
}

export function SchoolSearchInputRow({
  initialQuery,
  onSubmit,
}: SchoolSearchInputRowProps) {
  const [searchQuery, setSearchQuery] = useState<string>(initialQuery);
  const options = useSchoolSearchSuggestions(searchQuery);
  const [error, setError] = useState(false);

  // Reset "initialQuery" whenever the component is
  // re-rendered with a new value.
  useEffect(() => void setSearchQuery(initialQuery), [initialQuery]);

  // Change handler which updates the query state and data options.
  const handleChange = (value: string) => {
    setError(false);
    setSearchQuery(value);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      onSubmit(searchQuery);
    }
  };

  const handleSubmit = () => {
    onSubmit(searchQuery);
  };

  return (
    <SchoolSearchInputRowWrapper>
      <SearchInput
        hasError={error}
        placeholder="Search by school, city, state or ZIP code"
        options={options}
        onChange={handleChange}
        onSelect={onSubmit}
        onKeyUp={handleKeyUp}
        type="text"
        value={searchQuery}
        width="500px"
      />
      <Button onClick={handleSubmit}>Search</Button>
    </SchoolSearchInputRowWrapper>
  );
}

SchoolSearchInputRow.defaultProps = {
  initialQuery: '',
};
