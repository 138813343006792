import { InfoCard } from './InfoCard';

interface DataProps {
  latitude?: number;
  longitude?: number;
  schoolId: string;
}

export function Data({ latitude, longitude, schoolId }: DataProps) {
  return (
    <InfoCard title="Metadata">
      <div>ID: {schoolId}</div>
      <div>Latitude: {latitude ?? 'Unknown'}</div>
      <div>Longitude: {longitude ?? 'Unknown'}</div>
    </InfoCard>
  );
}
