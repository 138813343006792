import { School, UserProfile } from '@dao';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

interface ProfileRatingProps {
  profile: UserProfile;
  school: School;
}

const RatingWrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.string()};
  border-radius: 2px;
  box-shadow: 0 0 10px ${(props) => props.theme.grey.fade(0.5).string()};
  color: ${(props) => props.theme.textColor.string()};
  display: none;
  font-size: 0.9rem;
  margin: 0.4rem 0.4rem;
  padding: 0.7rem;
  text-align: center;

  em {
    font-style: italic;
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    display: inline-block;
  }
`;

const Rating = styled.div`
  svg {
    color: ${(props) => props.theme.yellow.string()};
    margin: 0.5rem;
    width: ${(props) => props.theme.iconSize};
  }
`;

export function ProfileRating({ profile, school }: ProfileRatingProps) {
  if (!profile || !school || !school.editors.includes(profile.uid)) {
    return null;
  }

  const stars = [1];

  for (let i = 0; i < school.rating - 4; i++) {
    stars.push(i + 2);
  }

  return (
    <RatingWrapper>
      <span>
        Add more details to <em>rank higher</em> in search results.
      </span>
      <Rating>
        {stars.map((key) => (
          <FaStar key={key} />
        ))}
      </Rating>
      <span>(this tip is only visible to you)</span>
    </RatingWrapper>
  );
}
