import { SearchOption } from '@shared';
import { fetch } from 'cross-fetch';
import { useEffect, useState } from 'react';

async function getCityStateList(
  query: string,
): Promise<SearchOption<string>[]> {
  const response = await fetch(
    `/api/location-autocomplete?q=${query}&citiesOnly=1`,
  );

  if (response.status !== 200) {
    return [];
  }

  const cityStateList: string[] = await response.json();

  return cityStateList.map((city) => ({ label: city, value: city }));
}

export function useCityStateOptions(query: string): SearchOption<string>[] {
  const [options, setOptions] = useState<SearchOption<string>[]>([]);

  useEffect(() => {
    getCityStateList(query).then(setOptions);
  }, [query]);

  return options;
}
