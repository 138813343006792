import { Pill } from '@shared';
import styled from 'styled-components';
import { SchoolSearchFilterOptions } from '../search/filters/types';
import { getSchoolAttributeNames } from '../utils';

const EmptyFiltersLabel = styled(Pill).attrs({ cyan: true })`
  cursor: pointer;
`;

const CollapsedFiltersWrapper = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: auto;
`;

const Label = styled.span`
  cursor: pointer;
`;

interface CollapsedFiltersProps {
  onExpand: () => void;
  options: SchoolSearchFilterOptions;
}

export function CollapsedFilters({ onExpand, options }: CollapsedFiltersProps) {
  const attributes = getSchoolAttributeNames(options);

  if (options.maxTuition) {
    attributes.push(`< $${(options.maxTuition / 100).toLocaleString()}`);
  }

  if (attributes.length) {
    attributes.push('+');
  }

  return (
    <CollapsedFiltersWrapper onClick={onExpand}>
      {attributes.length > 0 && <Label>Current search filters:</Label>}
      {attributes.map((filter) => (
        <Pill key={filter} cursor="pointer" cyan>
          {filter}
        </Pill>
      ))}
      {!attributes.length && (
        <EmptyFiltersLabel>
          Advanced search: click here to filter search results
        </EmptyFiltersLabel>
      )}
    </CollapsedFiltersWrapper>
  );
}
