import styled from 'styled-components';
import { usePromotedSchoolResults } from '../../hooks/usePromotedSchoolResults';
import { SchoolSearchFilters } from '../../search/filters/SchoolSearchFilters';
import { PromotedSchool } from './PromotedSchool';

interface PromotedProps {
  filters: SchoolSearchFilters;
  isLoadingResults: boolean;
}

const PromotedSchoolWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin: 3rem auto 2rem;
  width: 100%;
`;

export function Promoted({ filters, isLoadingResults }: PromotedProps) {
  const { results } = usePromotedSchoolResults(filters, isLoadingResults);

  if (!results.length) {
    return null;
  }

  return (
    <PromotedSchoolWrapper>
      {results.map((school) => (
        <PromotedSchool key={school.id} school={school} />
      ))}
    </PromotedSchoolWrapper>
  );
}
