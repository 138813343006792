import { ProductType, Subscription, SubscriptionDao } from '@dao';
import { logError } from '@reporting';
import { useEffect, useState } from 'react';

interface SubscriptionResult {
  isLoading: boolean;
  subscription: Subscription;
}

/** Gets the Subscription entity for a promoted school. */
export function usePromotedSchoolSubscription(
  schoolId: string,
  owner: string,
): SubscriptionResult {
  const [isLoading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState<Subscription>();

  useEffect(() => {
    if (!schoolId) {
      return;
    }

    setLoading(true);
    SubscriptionDao.getSubscriptionByEntityAndProduct(
      schoolId,
      owner,
      ProductType.PromotedSchool,
    )
      .then((result) => setSubscription(result))
      .catch((error) => logError(error))
      .finally(() => setLoading(false));
  }, [schoolId, owner]);

  return { isLoading, subscription };
}
