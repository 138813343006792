import { SchoolSearchFilterOptions } from './types';

export const DEFAULT_SCHOOL_SEARCH_OPTIONS: SchoolSearchFilterOptions = {
  city: null,
  state: null,
  zip: null,
  hasDayClasses: true,
  hasEveningClasses: false,
  hasOfflineClasses: true,
  hasOnlineClasses: false,
  hasWeekendClasses: false,
  isVerified: false,
  maxTuition: null,
};
