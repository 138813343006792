import { School, SchoolDao, SubscriptionDao } from '@dao';
import { logError } from '@reporting';
import shuffle from 'lodash/shuffle';
import { useEffect, useState } from 'react';
import { SchoolSearchFilters } from '../search/filters/SchoolSearchFilters';

interface PromotedSchoolResults {
  isLoading: boolean;
  results: School[];
}

/** React hook for retrieving promoted schools. */
export function usePromotedSchoolResults(
  filters: SchoolSearchFilters,
  isLoadingResults: boolean,
): PromotedSchoolResults {
  const [isLoading, setLoading] = useState(true);
  const [results, setResults] = useState<School[]>([]);

  useEffect(() => {
    if (isLoadingResults) {
      return;
    }

    setLoading(true);
    SubscriptionDao.listPromotedSchoolSubscriptions(filters)
      .then((subscriptions) => {
        if (subscriptions.results.length < 1) {
          setLoading(false);
          return;
        }

        const schoolIds = subscriptions.results.map(({ entityId }) => entityId);
        const shuffledIds = shuffle(schoolIds).slice(0, 3);

        SchoolDao.listSchoolsById(shuffledIds).then((schools) => {
          setResults(schools);
          setLoading(false);
        });
      })
      .catch((error) => {
        // Silently log error.
        logError(error);
      });
  }, [filters, filters.hash, isLoadingResults]);

  return { isLoading, results };
}
