import { School } from '@dao';
import { SchoolSearchFilterOptions } from '../../search/filters/types';
import {
  SCHOOL_ATTRIBUTE_KEY_LIST,
  SCHOOL_KEY_ATTRIBUTE_MAP,
} from './keyAttributeMap';
import { SchoolAttribute } from './list';

/**
 * Builds a list of attributes for the given school or search filter options.
 */
export function getSchoolAttributes(
  schoolOrOptions: School | SchoolSearchFilterOptions,
): SchoolAttribute[] {
  const attributes = [];

  for (const key of SCHOOL_ATTRIBUTE_KEY_LIST) {
    if (key in schoolOrOptions && schoolOrOptions[key]) {
      attributes.push(SCHOOL_KEY_ATTRIBUTE_MAP[key]);
    }
  }

  return attributes;
}
