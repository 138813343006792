import { InteractiveMap } from '@shared';
import { School } from '@dao';

interface SchoolProfileMapProps {
  school: School;
}

export function SchoolProfileMap({ school }: SchoolProfileMapProps) {
  // Default to all of the U.S.
  const center = school.latLon ?? { lat: 37.5041, lng: -95.8286 };
  const zoom = school.latLon ? 14 : 4.3;

  return (
    <InteractiveMap
      center={center}
      disableDefaultUI
      fullscreenControl
      zoom={zoom}
    />
  );
}
