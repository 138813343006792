import { School, Subscription, UserProfile } from '@dao';
import { ThirdHeading } from '@shared';
import { useState } from 'react';
import styled from 'styled-components';
import { Data } from './Data';
import { SchoolOwners } from './SchoolOwners';
import { Status } from './Status';
import { SubscriptionInfo } from './SubscriptionInfo';

interface MetadataProps {
  profile: UserProfile;
  school: School;
  subscription?: Subscription;
}

const MetadataWrapper = styled.div<{ visible: boolean }>`
  background-color: ${(props) =>
    props.theme.backgroundColor.fade(0.8).string()};
  border-radius: 4px;
  display: inline;
  margin: 1rem 1rem 2rem;
  padding: 1rem;
  width: ${(props) => (props.visible ? '100%' : 'auto')};
`;

const Title = styled(ThirdHeading)`
  cursor: pointer;
  font-size: 1rem;
  margin: auto;
`;

const Toggle = styled.span`
  font-size: 0.8em;
  color: ${(props) =>
    props.theme.textColor.mix(props.theme.white, 0.8).string()};
`;

const Notice = styled.div`
  color: ${(props) =>
    props.theme.textColor.mix(props.theme.white, 0.8).string()};
  font-size: 0.8rem;
  margin: auto auto 1rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export function Metadata({ profile, school, subscription }: MetadataProps) {
  const [visible, setVisible] = useState(false);

  return (
    <MetadataWrapper visible={visible}>
      <Title onClick={() => setVisible(!visible)}>
        Admin Functions <Toggle>(click to {visible ? 'hide' : 'show'})</Toggle>
      </Title>

      {visible && (
        <>
          <Notice>This information is only visible to admins</Notice>
          <Body>
            <Status visibility={school.visibility} />
            <SchoolOwners editors={school.editors} profile={profile} />
            <Data
              latitude={school.data.latitude}
              longitude={school.data.longitude}
              schoolId={school.id}
            />
            {subscription && (
              <SubscriptionInfo
                schoolName={school.data.name}
                subscription={subscription}
              />
            )}
          </Body>
        </>
      )}
    </MetadataWrapper>
  );
}
