import { School, UserProfile } from '@dao';
import { getProfileRoute } from '@shared';
import { FaSchool } from 'react-icons/fa';
import { Link } from './Link';

interface ManagedSchoolsProps {
  profile?: UserProfile;
  school?: School;
}

export function ManagedSchools({ profile, school }: ManagedSchoolsProps) {
  if (!profile || !school) {
    return null;
  }

  if (!school.editors.includes(profile.uid)) {
    return null;
  }

  return (
    <Link
      href={getProfileRoute('schools')}
      icon={<FaSchool />}
      text="My Schools"
    />
  );
}
