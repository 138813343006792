import { School, SchoolClaim, SchoolClaimDao, UserProfile } from '@dao';
import { logError } from '@reporting';
import {
  Button,
  ERROR_HAS_BEEN_REPORTED,
  ERROR_OCCURRED,
  FormRow,
  getRegisterRoute,
  Modal,
} from '@shared';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Link } from './Link';

interface BackProps {
  pendingClaims: SchoolClaim[];
  profile?: UserProfile;
  school?: School;
}

export function Claim({ pendingClaims, profile, school }: BackProps) {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  function handleHideModal() {
    setShowModal(false);
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleSendClaim() {
    setLoading(true);
    SchoolClaimDao.createSchoolClaim(
      SchoolClaim.forSchool(school.id, profile.uid),
    )
      .catch((error) => {
        logError(error);
        alert(`${ERROR_OCCURRED} ${ERROR_HAS_BEEN_REPORTED}`);
      })
      .finally(() => router.reload());
  }

  if (!school) {
    return null;
  }

  if (!profile && school.editors.length < 1) {
    return (
      <Link href={getRegisterRoute(school.route)} text="Claim this school" />
    );
  }

  if (
    profile &&
    pendingClaims.find((claim) => claim.profileId === profile.uid)
  ) {
    return null;
  }

  if (profile && !school.editors.includes(profile.uid) && !profile.isAdmin) {
    return (
      <>
        <Button onClick={handleShowModal} warning>
          Claim
        </Button>
        <Modal
          visible={showModal}
          onHide={handleHideModal}
          title={`Claim ${school.name}`}>
          <span>
            This will send a notification to the school owners, who will respond
            to your claim when they get a chance.
          </span>
          <FormRow horizontal>
            {!isLoading && (
              <Button onClick={handleHideModal} warning>
                Cancel
              </Button>
            )}
            <Button disabled={isLoading} onClick={handleSendClaim}>
              {isLoading ? 'Sending...' : 'Yes, send claim'}
            </Button>
          </FormRow>
        </Modal>
      </>
    );
  }

  return null;
}
