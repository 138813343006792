import { FaExternalLinkAlt } from 'react-icons/fa';
import styled from 'styled-components';

const Anchor = styled.a`
  color: ${(props) => props.theme.white.string()};
  text-decoration: none;

  span {
    margin-right: 0.4rem;
  }

  svg {
    width: ${(props) => props.theme.iconSize};
  }
`;

interface WebsiteLinkProps {
  url: string;
}

export function WebsiteLink({ url }: WebsiteLinkProps) {
  return (
    <Anchor href={url} target="_blank" rel="noreferrer">
      <span>Website</span>
      <FaExternalLinkAlt />
    </Anchor>
  );
}
