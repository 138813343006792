import { School, SchoolDao, SchoolVisibility, UserProfile } from '@dao';
import { logError } from '@reporting';
import {
  Button,
  ERROR_HAS_BEEN_REPORTED,
  ERROR_OCCURRED,
  FormRow,
  Modal,
} from '@shared';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';

interface PublishProps {
  profile?: UserProfile;
  school?: School;
}

const ReadyText = styled.div`
  text-align: center;
`;

export function Publish({ profile, school }: PublishProps) {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const updatedVisibility = profile?.isAdmin
    ? SchoolVisibility.Approved
    : SchoolVisibility.Ready;
  const publishText = profile?.isAdmin ? 'Approve' : 'Submit for Approval';
  const modalTitle = profile?.isAdmin
    ? 'Approve school page'
    : 'Send for approval';
  const modalText = profile?.isAdmin
    ? `Publish ${school.name}?`
    : `Ready to publish ${school.name}?`;
  const modalBtnText = profile?.isAdmin ? 'Approve' : 'Yes, send for approval';
  const loadingText = profile?.isAdmin ? 'Approving...' : 'Sending...';

  function handleHideModal() {
    setShowModal(false);
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handlePublish() {
    setLoading(true);
    SchoolDao.updateSchool(school.withVisibility(updatedVisibility))
      .then(() => router.reload())
      .catch((error) => {
        logError(error);
        alert(`${ERROR_OCCURRED} ${ERROR_HAS_BEEN_REPORTED}`);
      });
  }

  // We don't have the required info.
  if (!profile || !school) {
    return null;
  }

  // The user is not an editor nor an admin.
  if (!school.editors.includes(profile.uid) && !profile.isAdmin) {
    return null;
  }

  // The school is not in a publishable state.
  if (
    ![
      SchoolVisibility.Draft,
      SchoolVisibility.Ready,
      SchoolVisibility.Archived,
    ].includes(school.visibility)
  ) {
    return null;
  }

  // The school is missing crucial information.
  if (!school.isPublishable) {
    return null;
  }

  return (
    <>
      <Button onClick={handleShowModal}>{publishText}</Button>
      <Modal visible={showModal} onHide={handleHideModal} title={modalTitle}>
        <ReadyText>{modalText}</ReadyText>
        <FormRow horizontal>
          {!isLoading && (
            <Button onClick={handleHideModal} warning>
              Cancel
            </Button>
          )}
          <Button disabled={isLoading} onClick={handlePublish}>
            {isLoading ? loadingText : modalBtnText}
          </Button>
        </FormRow>
      </Modal>
    </>
  );
}
