import { Program } from '@dao';
import { costToString, dollarStringToCents } from '@utils';
import { useState } from 'react';

export interface ProgramInput extends Omit<Program, 'tuition'> {
  tuition: string;
}

export const blankProgramInput: ProgramInput = {
  description: '',
  name: '',
  tuition: '',
};

export function useProgramInput(initialPrograms?: Program[]) {
  const [errors, setErrors] = useState<string[]>([]);
  const [programs, setPrograms] = useState<ProgramInput[]>(
    initialPrograms
      ? initialPrograms.map((initialProgram) => ({
          ...initialProgram,
          tuition: costToString(initialProgram.tuition, false),
        }))
      : [],
  );

  function addFirst() {
    setPrograms((currPrograms) => [{ ...blankProgramInput }, ...currPrograms]);
  }

  function append(index: number) {
    setPrograms((currPrograms) => {
      const programs = [...currPrograms];

      programs.splice(index + 1, 0, { ...blankProgramInput });

      return programs;
    });
  }

  function format(): Program[] {
    return programs.map((program) => ({
      description: program.description.trim(),
      name: program.name.trim(),
      tuition: dollarStringToCents(program.tuition),
    }));
  }

  function remove(index: number) {
    setPrograms((currPrograms) =>
      currPrograms.filter((program, i) => i !== index),
    );
  }

  function update(index: number, key: keyof ProgramInput) {
    function onChange(value: string) {
      setPrograms((currPrograms) => {
        const programs = [...currPrograms];

        programs[index] = { ...programs[index], [key]: value };

        return programs;
      });
    }

    return onChange;
  }

  function validate(): string[] | void {
    const errors = programs.map((program, index, array) => {
      if (!program.name) {
        return 'Please provide a name for the program.';
      }

      const hasDuplicateName = array.findIndex(
        (pro, i) =>
          i < index && pro.name.toLowerCase() === program.name.toLowerCase(),
      );

      if (hasDuplicateName > -1) {
        return 'Duplicate program name.';
      }

      if (!program.description) {
        return 'Please provide a description for the program.';
      }

      if (Number(program.tuition) <= 0) {
        return 'Please provide a tuition cost.';
      }

      return '';
    });

    if (errors.some(Boolean)) {
      setErrors(errors);

      return errors;
    }
  }

  return {
    addFirst,
    append,
    errors,
    format,
    remove,
    update,
    validate,
    value: programs,
  };
}
