import { Subscription, SubscriptionDao } from '@dao';
import { useTextInput } from '@hooks';
import { logError } from '@reporting';
import {
  Button,
  ERROR_HAS_BEEN_REPORTED,
  ERROR_OCCURRED,
  FormError,
  FormRow,
  Label,
  Modal,
  TextInput,
} from '@shared';
import { toYearMonthDay } from '@utils';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';
import { InfoCard } from './InfoCard';

interface SubscriptionInfoProps {
  schoolName: string;
  subscription: Subscription;
}

const ModalParagraph = styled.p`
  text-align: center;
`;

// TODO: retrieve the Promoted School product name from Firestore
export function SubscriptionInfo({
  schoolName,
  subscription,
}: SubscriptionInfoProps) {
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const today = new Date();
  const defaultExpiryStr = toYearMonthDay(subscription.getMonthlyExtension());

  const router = useRouter();
  const expiryDate = useTextInput(defaultExpiryStr);

  function closeModal() {
    setModal(false);
  }

  function grantSubscription() {
    setLoading(true);

    const [year, month, day] = expiryDate.value.split('-');
    const expiryDateObj = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
    );

    if (handleError(expiryDateObj)) {
      return;
    }

    const updatedSubscription = subscription.withExpiryDate(expiryDateObj);

    SubscriptionDao.upsertSubscription(updatedSubscription)
      .then(() => router.reload())
      .catch((error) => {
        logError(error);
        alert(`${ERROR_OCCURRED} ${ERROR_HAS_BEEN_REPORTED}`);
      });
  }

  function handleError(date: Date) {
    if (date.valueOf() <= today.valueOf()) {
      expiryDate.updateError('Please select a date after today.');
      setLoading(false);
      return true;
    }

    expiryDate.updateError('');
    return false;
  }

  function openModal() {
    setModal(true);
  }

  return (
    <InfoCard title="Subscription">
      <div>{subscription?.expiryText}</div>

      <Button onClick={openModal}>
        {subscription?.isActive()
          ? 'Extend subscription'
          : 'Grant subscription'}
      </Button>

      <Modal onHide={closeModal} title="Grant Subscription" visible={modal}>
        <ModalParagraph>
          As an admin, you may grant a Promoted School subscription to{' '}
          {schoolName} for free.
        </ModalParagraph>

        <FormRow horizontal>
          <Label>Grant until:</Label>
          <TextInput
            disabled={isLoading}
            hasError={!!expiryDate.error}
            maxWidth="180px"
            onChange={expiryDate.updateValue}
            type="date"
            value={expiryDate.value}
          />

          <FormError msg={expiryDate.error} />
        </FormRow>

        <FormRow horizontal>
          {!isLoading && (
            <Button onClick={closeModal} warning>
              Cancel
            </Button>
          )}
          <Button disabled={isLoading} onClick={grantSubscription}>
            Grant
          </Button>
        </FormRow>
      </Modal>
    </InfoCard>
  );
}
