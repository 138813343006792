import { SearchOption } from '@shared';
import { useEffect, useState } from 'react';
import { getSchoolSearchSuggestions } from './getSchoolSearchSuggestions';
import { SchoolSearchSuggestions } from './types';

export function useSchoolSearchSuggestions(
  query: string,
): SearchOption<string>[] {
  const [suggestions, setSuggestions] = useState<SchoolSearchSuggestions>([]);

  useEffect(() => {
    getSchoolSearchSuggestions(query).then(setSuggestions);
  }, [query]);

  return suggestions;
}
