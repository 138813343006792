import { SchoolWithMetadata, UserProfile } from '@dao';
import { SchoolSearchFilterOptions, SchoolSearchFilters } from '@school';
import { LoadMore, ResultsWrapper, Section } from '@shared';
import { Filters } from '../filters/Filters';
import { Promoted } from './promoted/Promoted';
import { Results } from './results/Results';
import { SearchForm } from './SearchForm';

interface SchoolDirectoryProps {
  filters: SchoolSearchFilters;
  hasNextPage: boolean;
  isLoading: boolean;
  onNextPage: () => void;
  onUpdateOptions: (options: SchoolSearchFilterOptions) => void;
  onUpdateQuery: (query: string) => void;
  profile?: UserProfile;
  results: SchoolWithMetadata[];
}

export function SchoolDirectory({
  filters,
  hasNextPage,
  isLoading,
  onNextPage,
  onUpdateOptions,
  onUpdateQuery,
  profile,
  results,
}: SchoolDirectoryProps) {
  return (
    <Section>
      <ResultsWrapper>
        <SearchForm query={filters.query} onSubmit={onUpdateQuery} />
        <Filters onUpdateOptions={onUpdateOptions} options={filters.options} />
        <Promoted filters={filters} isLoadingResults={isLoading} />
        <Results isLoading={isLoading} profile={profile} schools={results} />
        <LoadMore
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          onNextPage={onNextPage}
        />
      </ResultsWrapper>
    </Section>
  );
}
