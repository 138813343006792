import { School } from '@dao';
import { SchoolSearchFilterOptions } from '../../search/filters/types';
import { getSchoolAttributeName } from './getSchoolAttributeName';
import { getSchoolAttributes } from './getSchoolAttributes';

/**
 * Gets the list of attribute names for the given school or search filter
 * options.
 */
export function getSchoolAttributeNames(
  schoolOrOptions: School | SchoolSearchFilterOptions,
): string[] {
  return getSchoolAttributes(schoolOrOptions).map(getSchoolAttributeName);
}
