import { SchoolDao, SchoolMetadataDao, SchoolWithMetadata } from '@dao';
import { FirestorePage } from '@libraries/firebase';
import { handleGenericError } from '@utils';
import { useEffect, useState } from 'react';
import { SchoolSearchFilters } from '../search/filters/SchoolSearchFilters';

interface SchoolSearchResults {
  isLoading: boolean;
  nextPage: FirestorePage;
  results: SchoolWithMetadata[];
}

/** React hook for retrieving school search results. */
export function useSchoolSearchResults(
  filters: SchoolSearchFilters,
  isLoadingFilters: boolean,
): SchoolSearchResults {
  const [isLoading, setLoading] = useState(true);
  const [results, setResults] = useState<SchoolWithMetadata[]>([]);
  const [nextPage, setNextPage] = useState<FirestorePage>(FirestorePage.FIRST);

  useEffect(() => {
    if (isLoadingFilters) {
      return;
    }

    setLoading(true);
    SchoolDao.listSchools(filters)
      .then((schools) => {
        setNextPage(schools.nextPage);

        if (schools.results.length < 1) {
          setResults([]);
          setLoading(false);
          return;
        }

        SchoolMetadataDao.listSchoolMetadataById(
          schools.results.map(({ id }) => id),
        ).then((metadata) => {
          const newResults = schools.results.map((school) => ({
            school,
            metadata: metadata.find(({ id }) => id === school.id),
          }));

          setResults((currentResults) =>
            filters.page.isFirstPage
              ? [...newResults]
              : [...currentResults, ...newResults],
          );
          setLoading(false);
        });
      })
      .catch(handleGenericError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.hash, isLoadingFilters]);

  return { isLoading, nextPage, results };
}
