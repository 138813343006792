import { School, SchoolClaim, UserProfile } from '@dao';
import styled from 'styled-components';
import { Back } from './Back';
import { Claim } from './Claim';
import { Edit } from './Edit';
import { Hints } from './Hints';
import { ManagedSchools } from './ManagedSchools';
import { Promote } from './Promote';
import { Publish } from './Publish';
import { ProfileRating } from './Rating';

const TopNavWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;

  @media (min-width: 800px) {
    margin: -2rem -1rem 0;
  }
`;

const ActionsAndHints = styled.div``;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  text-align: center;

  @media (min-width: 800px) {
    margin: 1rem;
    text-align: left;
  }
`;

interface TopNavProps {
  pendingClaims: SchoolClaim[];
  profile?: UserProfile;
  school?: School;
}

export function TopNav({ pendingClaims, profile, school }: TopNavProps) {
  return (
    <TopNavWrapper>
      <ActionsAndHints>
        <Actions>
          <Back stateCode={school.stateCode} />
          <Claim
            pendingClaims={pendingClaims}
            profile={profile}
            school={school}
          />
          <Edit profile={profile} school={school} />
          <Promote profile={profile} school={school} />
          <ManagedSchools profile={profile} school={school} />
          <Publish profile={profile} school={school} />
        </Actions>
        <Hints
          pendingClaims={pendingClaims}
          profile={profile}
          school={school}
        />
      </ActionsAndHints>

      <ProfileRating profile={profile} school={school} />
    </TopNavWrapper>
  );
}
