import { School, SchoolDao, SchoolVisibility } from '@dao';
import { useTextInput } from '@hooks';
import {
  AddressWithMap,
  Button,
  Card,
  ERROR_OCCURRED,
  Form,
  FormError,
  FormLabelRow,
  FormRow,
  logError,
  SearchInput,
  SecondHeading,
  Section,
  TextInput,
} from '@shared';
import { formatPhone } from '@utils';
import { FormEvent, useState } from 'react';
import styled from 'styled-components';
import { useAddressSelector } from '../hooks/useAddressSelector';
import { useCityStateOptions } from '../hooks/useCityStateOptions';
import { useSchoolListByName } from '../hooks/useSchoolListByName';
import { NameWarning } from '../NameWarning';

const Wrapper = styled(Card)`
  margin: 0 auto;
  max-width: 600px;
  padding: 1rem 2rem;
  width: 100%;
`;

interface CreateSchoolFormProps {
  onSchoolCreated: (school: School, preview: boolean) => void;
  userId: string;
}

export function CreateSchoolForm({
  onSchoolCreated,
  userId,
}: CreateSchoolFormProps) {
  const [isLoading, setLoading] = useState(false);
  const [namehasFocus, setNameFocus] = useState(false);

  const address = useAddressSelector();
  const name = useTextInput();
  const phone = useTextInput();

  const { schools } = useSchoolListByName(name.value.trim(), namehasFocus);
  const cityStateOptions = useCityStateOptions(address.cityState);

  function handleNameBlur() {
    setNameFocus(false);
  }

  function handleNameFocus() {
    setNameFocus(true);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!name.value.trim()) {
      return name.updateError('Please enter a name for the school.');
    }

    if (!address.address?.address && !address.text.trim().length) {
      return address.updateAddressError('Please enter a valid address.');
    }

    if (!address.cityState) {
      return address.updateCityStateError('Please select a city and state.');
    }

    // @ts-ignore: Property 'dataset' does not exist on type 'EventTarget'.
    const preview = event.target.dataset?.preview === 'true';

    const [city, state] = address.cityState.split(', ');
    const addressData =
      address.address?.address === address.text
        ? { ...address.address }
        : {
            address: address.text,
            city,
            latitude: null,
            longitude: null,
            state: state || '',
            zip: address.address?.zip || '',
          };

    // Save school details.
    const newSchool = School.create(
      { description: '', name: name.value, phone: phone.value, ...addressData },
      SchoolVisibility.Draft, // TODO: auto-approve if user is an admin.s
      [userId],
    );

    setLoading(true);
    SchoolDao.createSchool(newSchool)
      .then((createdSchool) => void onSchoolCreated(createdSchool, preview))
      .catch((cause) => {
        logError(new Error('Could not create new school', { cause }));
        alert(
          `${ERROR_OCCURRED} Please make sure you've verified your account.`,
        );
      });
  }

  return (
    <Section>
      <Wrapper>
        <Form alignItems="center" onSubmit={handleSubmit}>
          <SecondHeading>Add a school</SecondHeading>

          <FormRow>
            <FormLabelRow>School name</FormLabelRow>
            <TextInput
              disabled={isLoading}
              hasError={!!name.error}
              isLoading={isLoading}
              onBlur={handleNameBlur}
              onChange={name.updateValue}
              onFocus={handleNameFocus}
              placeholder="School name"
              type="text"
              value={name.value}
            />
            <FormError msg={name.error} />
          </FormRow>

          <NameWarning schools={schools} />

          <FormRow>
            <FormLabelRow>Address</FormLabelRow>
            <AddressWithMap
              address={address.address}
              hasError={!!address.addressErr}
              isLoading={isLoading}
              onChange={address.updateText}
              onSelect={address.selectAddress}
              placeholder="Address"
              type="text"
              value={address.text}
            />
            <FormError msg={address.addressErr} />
          </FormRow>

          <FormRow horizontal>
            <div>
              <SearchInput<string>
                flush
                onChange={address.updateCityState}
                onSelect={address.selectCityState}
                options={cityStateOptions}
                placeholder="City, State"
                value={address.cityState}
              />
              <FormError msg={address.cityStateErr} />
            </div>

            <TextInput
              onChange={address.updateZip}
              placeholder="ZIP"
              type="text"
              value={address.address?.zip || ''}
            />
          </FormRow>

          <FormRow>
            <FormLabelRow>Phone</FormLabelRow>
            <TextInput
              disabled={isLoading}
              formatter={formatPhone}
              hasError={!!phone.error}
              isLoading={isLoading}
              onChange={phone.updateValue}
              placeholder="Contact phone #"
              type="text"
              value={phone.value}
            />
            <FormError msg={phone.error} />
          </FormRow>

          <FormRow horizontal>
            <Button
              data-preview="true"
              disabled={isLoading}
              onClick={handleSubmit}
              secondary>
              Preview
            </Button>
            <Button
              data-preview="false"
              disabled={isLoading}
              onClick={handleSubmit}>
              Continue
            </Button>
          </FormRow>
        </Form>
      </Wrapper>
    </Section>
  );
}
