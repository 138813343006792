import { SchoolVisibility } from '@dao';
import { Pill } from '@shared';
import { InfoCard } from './InfoCard';

interface StatusProps {
  visibility: SchoolVisibility;
}

export function Status({ visibility }: StatusProps) {
  return (
    <InfoCard title="Status">
      {visibility === SchoolVisibility.Draft && (
        <Pill autoMargin inHighlightedSection yellow>
          Draft
        </Pill>
      )}

      {visibility === SchoolVisibility.Ready && (
        <Pill autoMargin inHighlightedSection yellow>
          Pending approval
        </Pill>
      )}

      {visibility === SchoolVisibility.Approved && (
        <Pill autoMargin inHighlightedSection yellow>
          Approved
        </Pill>
      )}

      {visibility === SchoolVisibility.Archived && (
        <Pill autoMargin inHighlightedSection yellow>
          Archived
        </Pill>
      )}
    </InfoCard>
  );
}
